var Trimova = Trimova || function() {};

Trimova = (function() {
  function setupPopup () {
    // $(window).on('blur', function() {
    //   $('#mailchimp-modal').modal({
    //     backdrop: true,
    //     keyboard: true,
    //     show: true,
    //   });
    // });

    $(document).on('click', 'a[href="#popup-open"]', function(e) {
      e.preventDefault();
      $('.popup').fadeIn('fast', function() {
        $(document).on('click', '.popup .close', function() {
          $('.popup').fadeOut('fast');
        });
      });
    })
  }

  function setupRequests() {
    $(document).on('click', '.btn-requests', function(e) {
      e.preventDefault();
      $('.requests-box').slideToggle();
      $('html, body').animate({scrollTop:$(this).offset().top});
    });
  }

  function setupGoogleMaps() {
    var map = new google.maps.Map(document.querySelector('.map'), {
      center: {lat: 47.460277, lng: 19.13938},
      zoom: 15
    });
    var marker = new google.maps.Marker({
      position: {lat: 47.460277, lng: 19.13938},
      map: map
    });
  }

  return {
    init: function() {
      setupPopup();
      setupRequests();
    },
    map: function() {
      setupGoogleMaps();
    }
  }
})();
